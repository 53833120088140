import React, { forwardRef, ReactNode } from 'react'

type LinkMockProps = {
  children?: ReactNode
  className?: string
  id?: string
  onClick?(): void
  to?: string
}

const LinkMock = forwardRef<HTMLAnchorElement, LinkMockProps>(
  ({ children, className, id, onClick, to = '#', ...props }, ref) => (
    <a
      {...props}
      className={className}
      href={to}
      id={id}
      onClick={event => {
        event.preventDefault()
        onClick?.()
      }}
      ref={ref}
    >
      {children}
    </a>
  )
)

const LinkMockTargetBlank = forwardRef<HTMLAnchorElement, LinkMockProps>(
  ({ children, className, id, onClick, to = '#', ...props }, ref) => (
    <a
      {...props}
      className={className}
      href={to}
      id={id}
      onClick={event => {
        event.preventDefault()
        onClick?.()
      }}
      target="_blank"
      rel="noopener noreferrer"
      ref={ref}
    >
      {children}
    </a>
  )
)

LinkMock.displayName = 'Link'
LinkMockTargetBlank.displayName = 'Link'

export { LinkMock, LinkMockTargetBlank }
