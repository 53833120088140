import React, { ComponentProps, useState } from 'react'

import { PlainSelect } from '@te-digi/styleguide'

const OPTIONS = [
  { label: 'Suomi', value: 'fi' },
  { label: 'Ruotsi', value: 'sv' },
  { label: 'Englanti', value: 'en' },
  { label: 'Selkosuomi', value: 'ss' },
  { label: 'Saame', value: 'se' }
]

const PlainSelectExample1 = () => {
  const [value, setValue] =
    useState<ComponentProps<typeof PlainSelect>['value']>('fi')

  return (
    <PlainSelect
      ariaLabel="Valitse kieli"
      onChange={e => setValue(e)}
      options={OPTIONS}
      value={value}
    />
  )
}

export { PlainSelectExample1 }
