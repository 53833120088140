import React from 'react'

import {
  BookIcon,
  BriefcaseIcon,
  Button,
  Dropdown,
  DropdownActions,
  DropdownActionsItem,
  LicenseIcon,
  List,
  ListItem,
  PlusIcon,
  ProfileIcon,
  RibbonIcon,
  Spacer,
  StarIcon,
  Text,
  Timeline,
  TimelineItem
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { LinkMock } from '../../components/LinkMock'
import { Section } from '../../components/Section'

export const TimelineExample1 = () => (
  <Timeline truncatedText="+ 8 muuta merkintää">
    <TimelineItem
      icon={<BriefcaseIcon />}
      shape="square"
      size="lg"
    >
      <Text
        as="p"
        lineHeight="sm"
      >
        Hitsarit X<br />
        <Text fontWeight="semi-bold">Hitsaaja</Text>
        <br />
        1/2017 - 12/2018
      </Text>
    </TimelineItem>
    <TimelineItem
      icon={<BookIcon />}
      size="lg"
    >
      <Text
        as="p"
        lineHeight="sm"
      >
        Lukio
        <br />
        <Text fontWeight="semi-bold">Tampereen yhteiskoulun lukio</Text>
        <br />
        2013-2016
      </Text>
    </TimelineItem>
    <TimelineItem
      icon={<BookIcon />}
      size="lg"
    >
      <Text
        as="p"
        lineHeight="sm"
      >
        Hämeen ammattikoulu
        <br />
        <Text fontWeight="semi-bold">Sähköalan perustutkinto</Text>
        <br />
        7/1996 - 5/2000
      </Text>
    </TimelineItem>
    <TimelineItem icon={<LicenseIcon />}>
      <Text
        as="p"
        lineHeight="sm"
      >
        Ajokortti
        <br />
        <Text fontWeight="semi-bold">B-luokka</Text>
        <br />
        2012
      </Text>
    </TimelineItem>
    <TimelineItem icon={<ProfileIcon />}>
      <Text
        as="p"
        lineHeight="sm"
      >
        Hygieniapassi
        <br />
        2011
      </Text>
    </TimelineItem>
  </Timeline>
)

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Timeline"
    components={[{ component: Timeline }, { component: TimelineItem }]}
  >
    <Section>
      <List variant="unordered">
        <ListItem>
          <Code>Timeline</Code> koostuu viivalla yhdistetyistä{' '}
          <Code>TimelineItem</Code>-komponenteista, joille voi määritellä
          ikonin, ikonia ympäröivän muodon, sekä vieressä näkyvän vapaan
          sisällön.
        </ListItem>
        <ListItem>
          Ikonin tilalle on myös mahdollista laittaa vapaasti muita
          komponentteja, esim. painikkeen.
        </ListItem>
      </List>
      <Playground>{TimelineExample1()}</Playground>
      <Playground enableOverflow>
        <>
          <Spacer marginBottom="lg">
            <Text align={{ md: 'center' }}>
              <Dropdown
                placement="bottom"
                trigger={<Button iconLeft={<PlusIcon />}>Lisää uusi</Button>}
              >
                <DropdownActions>
                  {(
                    [
                      { label: 'Työkokemus', icon: <BriefcaseIcon /> },
                      { label: 'Koulutus', icon: <BookIcon /> },
                      { label: 'Kortti tai lupa', icon: <ProfileIcon /> },
                      { label: 'Ajokortti', icon: <LicenseIcon /> },
                      {
                        label: 'Sertifikaatti tai julkaisu',
                        icon: <RibbonIcon />
                      },
                      { label: 'Muu kokemus', icon: <StarIcon /> }
                    ] as const
                  ).map(item => (
                    <DropdownActionsItem
                      as={LinkMock}
                      icon={item.icon}
                      key={item.label}
                      to="#"
                    >
                      {item.label}
                    </DropdownActionsItem>
                  ))}
                </DropdownActions>
              </Dropdown>
            </Text>
          </Spacer>
          <Timeline align="center">
            <TimelineItem
              as={LinkMock}
              icon={<BriefcaseIcon />}
              shape="square"
              size="lg"
            >
              <Text
                as="p"
                lineHeight="sm"
              >
                Hitsarit X<br />
                <Text fontWeight="semi-bold">
                  Hitsaaja
                  <br />
                  1/2017 - 12/2018
                </Text>
              </Text>
            </TimelineItem>
            <TimelineItem
              as={LinkMock}
              icon={<BookIcon />}
              size="lg"
            >
              <Text
                as="p"
                lineHeight="sm"
              >
                Lukio
                <br />
                <Text fontWeight="semi-bold">
                  Tampereen yhteiskoulun lukio
                  <br />
                  2013-2016
                </Text>
              </Text>
            </TimelineItem>
            <TimelineItem
              as={LinkMock}
              icon={<ProfileIcon />}
            >
              <Text
                as="p"
                lineHeight="sm"
              >
                Ajokortti
                <br />
                <Text fontWeight="semi-bold">
                  B-luokka
                  <br />
                  2012
                </Text>
              </Text>
            </TimelineItem>
          </Timeline>
        </>
      </Playground>
    </Section>
  </Content>
)

export default Page
