import React, { Fragment, ReactNode, useState } from 'react'

import {
  Button,
  Chat,
  ChatButtons,
  ChatMessage,
  useInterval
} from '@te-digi/styleguide'

import lorem, { useLorem } from '../../lorem'

interface ChatExample1Props {
  isOpen?: boolean
}

const ChatExample1 = ({ isOpen: initialIsOpen }: ChatExample1Props) => {
  const [entries, setEntries] = useState<ReactNode[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(initialIsOpen)
  const lorem2 = useLorem({ words: 5 })
  const lorem3 = useLorem()

  const addEntry = (Component: ReactNode) => {
    setEntries([...entries, Component])
  }

  useInterval(() => {
    if (!isOpen) {
      return
    }

    if (Math.random() < 0.5 && isLoading) {
      addEntry(
        <ChatMessage prefixText="Palautebotti sanoo:">
          {lorem({ sentences: Math.random() * 2 + 1 })}
        </ChatMessage>
      )

      if (Math.random() < 0.2) {
        const hasVerticalButtons = Math.random() > 0.5

        addEntry(
          hasVerticalButtons ? (
            <ChatButtons vertical>
              <Button variant="outline">
                {lorem({ words: Math.random() * 3 + 1 })}
              </Button>
              <Button variant="outline">
                {lorem({ words: Math.random() * 3 + 1 })}
              </Button>
              <Button variant="outline">
                {lorem({ words: Math.random() * 3 + 1 })}
              </Button>
            </ChatButtons>
          ) : (
            <ChatButtons>
              <Button variant="outline">{lorem({ words: 1 })}</Button>
              <Button variant="outline">
                {lorem({ words: Math.random() * 2 + 1 })}
              </Button>
            </ChatButtons>
          )
        )
      }

      setIsLoading(false)
    } else {
      setIsLoading(true)
    }
  }, 5000)

  return (
    <Chat
      isOpen={isOpen}
      labelText="Lähetettävä viesti"
      loading={isLoading}
      onSubmit={message =>
        addEntry(
          <ChatMessage
            isUser
            prefixText="Minä sanon:"
          >
            {message}
          </ChatMessage>
        )
      }
      onToggle={() => setIsOpen(!isOpen)}
      placeholder="Kirjoita viestisi tähän"
      submitText="Lähetä"
      title="Palautebotti"
    >
      <ChatMessage prefixText="Palautebotti sanoo:">
        Hei! Löysitkö etsimäsi? Olen chatbot ja voin auttaa.
      </ChatMessage>
      <ChatButtons>
        <Button variant="outline">Löysin</Button>
        <Button variant="outline">En</Button>
      </ChatButtons>
      <ChatMessage
        isUser
        prefixText="Minä sanon:"
      >
        {lorem2}.
      </ChatMessage>
      <ChatMessage prefixText="Palautebotti sanoo:">{lorem3}</ChatMessage>
      <ChatButtons vertical>
        <Button variant="outline">Selaa työpaikkoja</Button>
        <Button variant="outline">Yrityksen perustaminen</Button>
      </ChatButtons>
      {entries.map((entry, index) => (
        <Fragment key={index}>{entry}</Fragment>
      ))}
    </Chat>
  )
}

export { ChatExample1 }
