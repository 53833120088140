import React, { ReactElement, useState } from 'react'

import {
  Button,
  Buttons,
  Dropdown,
  DropdownSection,
  Header,
  HeaderBackground,
  HeaderButton,
  HeaderSearch,
  SpeechBubbleFillIcon,
  TePalvelutFillIcon,
  TyomarkkinatoriAsiantuntijaFiWhiteSvg,
  TyomarkkinatoriFiWhiteSvg,
  useBreakpoint,
  UserIcon
} from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

import { PlainSelectExample1 } from './PlainSelectExample1'

interface HeaderExampleProps {
  activePrimaryItem?: string
  activeSecondaryItem?: string
  hasUser?: boolean
  hasCompany?: boolean
  isCompany?: boolean
  isConsultant?: boolean
}

const createMenu = (
  activePrimaryItem?: string,
  activeSecondaryItem?: string,
  isConsultant?: boolean,
  isCompany?: boolean
) => {
  const createMenuItem = (
    label: string,
    isPrimary = false,
    children: any[] | undefined = [],
    divider = false,
    icon: ReactElement | undefined = undefined
  ) => ({
    active: label === (isPrimary ? activePrimaryItem : activeSecondaryItem),
    component: <LinkMock to={`#${label}`} />,
    label,
    children,
    divider,
    icon
  })

  return isConsultant
    ? [
        createMenuItem('Työntehtävät', true),
        createMenuItem('Henkilöasiakkaat', true),
        createMenuItem('Työnantajat ja yrittäjät', true),
        createMenuItem('Palvelut', true)
      ]
    : [
        createMenuItem('Henkilöasiakkaat', true, [
          createMenuItem('Työnhaku'),
          createMenuItem('Koulutus ja ura'),
          createMenuItem('Eri tavat työllistyä'),
          createMenuItem('Yrityksen perustaminen'),
          createMenuItem('Ammattitieto'),
          createMenuItem('Työttömyys', false, [
            createMenuItem('Ilmoittautuminen työttömäksi työnhakijaksi')
          ])
        ]),
        createMenuItem('Työnantajat ja yrittäjät', true),
        createMenuItem('Yhteistyökumppanit', true),
        createMenuItem(
          'Omat sivut',
          true,
          isCompany
            ? [
                createMenuItem('Työpaikkailmoitukset'),
                createMenuItem('Hae työntekijöitä'),
                createMenuItem('Yrityksen tiedot'),
                createMenuItem('Omat tiedot')
              ]
            : [
                createMenuItem('Oma työnhaku'),
                createMenuItem('Työnhakuprofiili'),
                createMenuItem('Selaa työpaikkoja'),
                createMenuItem('Suositellut työpaikat'),
                createMenuItem('Työllistä kotitaloutena')
              ],
          true,
          <SpeechBubbleFillIcon color={'white'} />
        ),
        createMenuItem(
          'Asiointi',
          true,
          undefined,
          false,
          <TePalvelutFillIcon color={'brand'} />
        )
      ]
}

// This example is only used in other examples and pattern pages
export const HeaderExample = ({
  activePrimaryItem,
  activeSecondaryItem,
  hasUser,
  hasCompany,
  isCompany,
  isConsultant
}: HeaderExampleProps = {}) => {
  const isLg = useBreakpoint('lg')
  const [isNavOpen, setIsNavOpen] = useState(false)

  const hasUserContent = (
    <Dropdown
      placement="bottom-end"
      trigger={
        <HeaderButton
          icon={<UserIcon />}
          subLabel={hasCompany ? 'Matin Nakkikioski' : undefined}
        >
          Matti
        </HeaderButton>
      }
    >
      <DropdownSection>
        <Buttons
          align="center"
          noMargin
        >
          <Button>Kirjaudu ulos</Button>
        </Buttons>
      </DropdownSection>
    </Dropdown>
  )

  const noUserContent = (
    <HeaderButton icon={<UserIcon />}>
      {isLg ? 'Kirjaudu sisään' : 'Kirjaudu'}
    </HeaderButton>
  )

  return (
    <HeaderBackground>
      <Header
        isNavOpen={isNavOpen}
        languageSelect={PlainSelectExample1()}
        logo={
          <LinkMock>
            {isConsultant ? (
              <TyomarkkinatoriAsiantuntijaFiWhiteSvg ariaLabel="Siirry etusivulle" />
            ) : (
              <TyomarkkinatoriFiWhiteSvg ariaLabel="Siirry etusivulle" />
            )}
          </LinkMock>
        }
        menu={createMenu(
          activePrimaryItem,
          activeSecondaryItem,
          isConsultant,
          isCompany
        )}
        onNavToggle={() => setIsNavOpen(!isNavOpen)}
        search={
          isConsultant ? undefined : (
            <HeaderSearch
              onSubmit={() => console.log('HeaderSearch: onSubmit')}
            />
          )
        }
        user={hasUser ? hasUserContent : noUserContent}
      />
    </HeaderBackground>
  )
}
