import React from 'react'
import { Footer, FooterItem, Link } from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

const FooterExample = () => (
  <>
    {/* EXAMPLE_START */}
    <Footer>
      <FooterItem>&copy; KEHA-keskus</FooterItem>
      <FooterItem>
        <Link
          as={LinkMock}
          isOnDark
        >
          Tietoa palvelusta
        </Link>
      </FooterItem>
      <FooterItem>
        <Link
          as={LinkMock}
          isOnDark
        >
          Käyttöehdot
        </Link>
      </FooterItem>
      <FooterItem>
        <Link
          as={LinkMock}
          isOnDark
        >
          Tietosuojaseloste
        </Link>
      </FooterItem>
      <FooterItem>
        <Link
          as={LinkMock}
          isOnDark
        >
          Ohjeet ja UKK
        </Link>
      </FooterItem>
      <FooterItem>
        <Link
          as={LinkMock}
          isOnDark
        >
          Saavutettavuusseloste
        </Link>
      </FooterItem>
      <FooterItem>
        <Link
          as={LinkMock}
          isOnDark
        >
          Lähetä palautetta
        </Link>
      </FooterItem>
    </Footer>
    {/* EXAMPLE_END */}
  </>
)

export { FooterExample }
