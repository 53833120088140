import React from 'react'

import { Head } from '../../components/Head'
import { ProfileExample } from '../../examples/react/pages/ProfileExample'

const Page = () => (
  <>
    <Head title="Työnhakuprofiili" />
    <ProfileExample />
  </>
)

export default Page
